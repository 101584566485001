:root {
  --apple: #4caf50;
  --black: #000000;
  --bon-jour: #e0e0e080;
  --d1opnt: #fffefc;
  --ghost: #c7ccd0;
  --gray: #818181;
  --na3s1y: #9fa0a0;
  --nfwtdp: #ed5b2d;
  --pippin: #ffe5dd;
  --razzmatazz: #e91f63;
  --san-juan: #344767;
  --vqi5wu: #291b25;
  --waterloo-: #7b809a;
  --white: #ffffff;
  --x-hover: #fb8560;
  --x-pressed: #ce4f27;
  --x1: #61a6ab;
  --x3: #ffce6d;
  --x4: #f7b9a1;
  --x5: #291b25;

  --font-size-l: 16px;
  --font-size-ml: 15px;
  --font-size-m: 14px;
  --font-size-s: 13px;
  --font-size-ss: 10px;
  --font-size-xl: 18px;
  --font-size-xxl: 22px;
  --font-size-xxxl: 24px;
  --font-size-xxxxl: 68px;

  --font-family-ibm_plex_sans: "IBM Plex Sans", Helvetica;
  --font-family-noto_sans_jp: "Noto Sans JP", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --font-family-roboto-medium: "Roboto-Medium", Helvetica;
}

.notosansjp-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}



.notosansjp-medium-black-14px {
  color: var(--black);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.notosansjp-medium-black-15px {
  color: var(--black);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-ml);
  font-style: normal;
  font-weight: 500;
}


.notosansjp-normal-star-dust-14px {
  color: var(--na3s1y);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.notosansjp-bold-bastille-13px {
  color: var(--vqi5wu);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.roboto-normal-waterloo--14px {
  color: var(--waterloo-);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.roboto-light-waterloo--14px {
  color: var(--waterloo-);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 300;
}

.roboto-bold-san-juan-24px {
  color: var(--san-juan);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.notosansjp-bold-black-18px {
  color: var(--black);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.roboto-bold-white-16px {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.roboto-bold-apple-16px {
  color: var(--apple);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.roboto-light-waterloo--16px {
  color: var(--waterloo-);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 300;
}

.roboto-bold-salmon-16px {
  color: var(--x-hover);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.notosansjp-bold-star-dust-16px {
  color: var(--na3s1y);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.notosansjp-normal-white-14px {
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.notosansjp-normal-flamingo-14px {
  color: var(--nfwtdp);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.notosansjp-normal-apple-14px {
  color: var(--apple);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.notosansjp-normal-waterloo--14px {
  color: var(--waterloo-);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.roboto-bold-san-juan-22px {
  color: var(--san-juan);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.roboto-normal-waterloo--16px {
  color: var(--waterloo-);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.notosansjp-medium-romance-14px {
  color: var(--d1opnt);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.roboto-normal-white-14px {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.roboto-bold-razzmatazz-14px {
  color: var(--razzmatazz);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.notosansjp-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.notosansjp-bold-flamingo-16px {
  color: var(--nfwtdp);
  font-family: var(--font-family-noto_sans_jp);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.ibmplexsans-bold-romance-16px {
  color: var(--d1opnt);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.ibmplexsans-normal-gray-16px {
  color: var(--gray);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.border-1px-ghost {
  border: 1px solid var(--ghost);
}

.border-1px-x1 {
  border: 1px solid var(--x1);
}

.border-1px-na3s1y {
  border: 1px solid var(--na3s1y);
}


:root {
  --anakiwa: #92dfff;
  --anakiwa-2: #91dfff;
  --black: #000000;
  --cloud-burst: #192e55;
  --curious-blue: #2397ce;
  --fresh-air: #a7e0f7;
  --lily-white: #e4f7ff;
  --moonstone: #3b9fca;
  --mystic: #e5eced;
  --onahau: #d2f2ff;
  --robins-egg-blue: #1ed6ff;
  --sea-serpent: #4dcbff;
  --sky-blue: #8cdaeb;
  --star-dust: #9fa0a0;
  --twilight-blue: #eefaff;
  --white: #ffffff;

  --font-size-l: 12px;
  --font-size-m: 8px;
  --font-size-xl: 14px;
  --font-size-xxl: 15px;
  --font-size-xxxl: 16px;
  --font-size-xxxxl: 18px;
  --font-size-xxxxxl: 36px;

  --font-family-noto_sans_jp: "Noto Sans JP", Helvetica;
}
.amplify-input {
    border: none;

}

.amplify-field button {
    border: none;

}

[data-amplify-authenticator][data-variation=modal] {
    /* background-image: url(/src/img/nature-ocean-waves-wallpaper-1920x1200_6.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */

    background: transparent;

}

[data-amplify-authenticator] [data-amplify-container] {
    /* place-self: self-end; */
    place-self: center;
    /* height: 100%; */
    /* padding: 50px; */


}

[data-amplify-authenticator] [data-amplify-router] {
    border: none;
    background-color: rgba(255, 255, 255, 0.2);

}


[data-amplify-authenticator] [data-state=inactive] {
    background-color: rgba(255, 255, 255, 0.3);

}

.holder,
.first,
.second,
.third {
    height: 100vh;
    width: 100vw;
}

.first,
.second,
.third {
    position: absolute;
}

.second,
.third {
    opacity: 0;
}

.holder {
    height: 100vh;
    position: relative;
    z-index: 1;
}

.first {
    animation: first 10s infinite;
    /* background: linear-gradient(#5ff8ca, #60e08c); */
    background-color: #ffffff;
    z-index: 10;
}

@keyframes first {
    0% {
        opacity: 1.0;
    }

    10% {
        opacity: 0.8;
    }

    20% {
        opacity: 0.6;
    }

    30% {
        opacity: 0.4;
    }

    40% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.1;
    }

    60% {
        opacity: 0.2;
    }

    70% {
        opacity: 0.4;
    }

    80% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.8;
    }

    100% {
        opacity: 1.0;
    }
}

.second {
    animation: second 10s infinite;
    animation-delay: 2s;
    /* background: linear-gradient(#19eaa6, #00a1f0); */
    background-color: #ffffff;
    z-index: 20;
}

@keyframes second {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0.2;
    }

    20% {
        opacity: 0.4;
    }

    30% {
        opacity: 0.6;
    }

    40% {
        opacity: 0.8;
    }

    50% {
        opacity: 1.0;
    }

    60% {
        opacity: 0.8;
    }

    70% {
        opacity: 0.6;
    }

    80% {
        opacity: 0.4;
    }

    90% {
        opacity: 0.2;
    }

    100% {
        opacity: 0;
    }
}

.third {
    animation: third 10s infinite;
    animation-delay: 8s;
/*    background: linear-gradient(#aab7f8, #ff75c6);*/
    background-color: #ffffff;
    z-index: 30;
}

@keyframes third {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0.2;
    }

    20% {
        opacity: 0.4;
    }

    30% {
        opacity: 0.6;
    }

    40% {
        opacity: 0.8;
    }

    50% {
        opacity: 1.0;
    }

    60% {
        opacity: 0.8;
    }

    70% {
        opacity: 0.6;
    }

    80% {
        opacity: 0.4;
    }

    90% {
        opacity: 0.2;
    }

    100% {
        opacity: 0;
    }
}
.editing.print_pages {
    /*A4縦*/
    /* width: 210mm;
    height: 297mm; */
    width: 296mm;
    height: 209mm;
    margin: 0px;
    page-break-after: always;
    /* 印刷を想定したサイズを点線で表示 */
    /* border-bottom: 1px dashed #CCC;
    border-right: 1px dashed #CCC; */

    background-color: white;
    box-shadow: 2px 2px 50px 0 rgba(147, 163, 169, 0.5);
    border: unset;

}

.page-list .print_pages {
    box-shadow: unset;
    margin: unset;

}

.page-list-item {
    cursor: pointer;
}

/*最後のページは改ページを入れない*/
.print_pages:last-child {
    page-break-after: never;
}

@media print {
    @page {
        /* size: 297mm 210mm; */
        size: A4 landscape;
        margin: 0;
    }

    /* 印刷時は点線表示なし */
    .print_pages {
        border-bottom: none;
        border-right: none;
    }
}

.edit-menu-frame {
    /* background-color: white; */

}

.chart_select_menu {
    padding-top: 70px;
    height: calc(100vh - 170px);
    overflow-y: scroll;
}

/* 

.chart_select_menu div {
    margin: 10px 20px;
}

.chart_select_menu div button {
    width: 100%;
}
 */

.chart_select_header.active {
    color: black;
}

.chart_select_header.inactive {
    color: gray;
}

.page_property {
    margin: 50px;
}

.page_property .property {
    margin: 5px 0;
    background-color: lightgray;
    padding: 5px;
}

.page_property .property-key {
    padding: 5px;
}

.page_property .property-value {
    background-color: white;
    padding: 5px;
    font-family: monospace;
}

html {
    height: 100%;
}

body {
    height: 100%;

    /* background: linear-gradient(120deg, #ffffff, #E6EFF3); */

}

div#root {
    height: 100%;
}

/* react-tabs */

.react-tabs {
    -webkit-tap-highlight-color: transparent;
    height: 100%;
}

.edit-menu-frame .react-tabs__tab-list {
    border-bottom: unset;
    margin: 0;
    padding: 0;
}

.edit-menu-frame .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    vertical-align: middle;
    color: #2397CE;
    font-size: 10pt;
}

.edit-menu-frame .react-tabs__tab img {
    vertical-align: middle;
}


.edit-menu-frame .react-tabs__tab--selected {
    background: #fff;
    /* border-color: #aaa; */
    color: #2397CE;
    /* border-radius: 5px 5px 0 0; */
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

/* .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
} */

.react-tabs__tab-panel {
    display: none;
}

.edit-menu-frame .react-tabs__tab-panel--selected {
    display: block;
    background-color: white;
    height: 100%;
}
.print_pages {
    /*A4縦*/
    /* width: 210mm;
    height: 297mm; */
    width: 296mm;
    height: 209mm;
    margin: 0;
    page-break-after: always;
    /* 印刷を想定したサイズを点線で表示 */
    border: 1px dashed #CCC;


}

/*最後のページは改ページを入れない*/
/* .print_pages:last-child {
    page-break-after: never;
} */

@media print {
    @page {
        /* size: 297mm 210mm; */
        size: A4 landscape;
        margin: 0;
    }

    /* 印刷時は点線表示なし */
    .print_pages {
        border-bottom: none;
        border-right: none;
    }
}